import React from 'react'
import CountDownTimer from '../components/CountDownTimer';
const CountDown = () => {
  return (
    <div>
      <CountDownTimer date="12/17/2023" />
    </div>
  )
}

export default CountDown
